<template>
  <div>
    <div v-show="detailShow">
      <show ref="show" @close="handleClose"></show>
    </div>
    <vx-card v-show="!detailShow" :title="title">
      <div class="vx-row mb-6">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Date</label>
          <vs-input
            class="w-full"
            v-model="date"
            type="date"
            v-validate="'required'"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Territory</label>
          <territory-v2
            @data="setTerritoryId"
            :isMultiple="true"
            :isAllowEmpty="true"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col flex items-center justify-items-start gap-2">
          <vs-button
            @click="onClickSearch"
            size="small"
            color="primary"
            icon-pack="feather"
            icon="icon-search"
            title="Search"
          >
            Search</vs-button
          >
        </div>
      </div>

      <div class="vx-row">
        <vs-tabs>
          <vs-tab label="Wait Approval">
            <div class="con-tab-ejemplo">
              <table-wait-approval
                @data-print="onClickPrint"
                @open-detail="handleShowDetail"
                ref="table_wait_approval"
              ></table-wait-approval>
            </div>
          </vs-tab>
          <vs-tab label="Approved">
            <div class="con-tab-ejemplo">
              <table-approved
                @data-print="onClickPrint"
                @open-detail="handleShowDetail"
                ref="table_approved"
              ></table-approved>
            </div>
          </vs-tab>
          <!-- <vs-tab disabled label="Processed">
            <div class="con-tab-ejemplo">Comming soon</div>
          </vs-tab> -->
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import moment from "moment";
import TableWaitApproval from "./_tabs/table-wait-approval.vue";
import TableApproved from "./_tabs/table-approved.vue";
import TerritoryV2 from "@/components/master/TerritoryV2.vue";
import Show from "./show.vue";

export default {
  components: {
    TableWaitApproval,
    TableApproved,
    TerritoryV2,
    Show,
  },
  data() {
    return {
      detailShow: false,
      title: "Manage Collection Plan (CIT)",
      date: null,
      // select territory
      selectedTerritory: [],
      optionTerritory: [],
    };
  },
  mounted() {
    // this.fetchTerritory();
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
  },
  methods: {
    async setTerritoryId(datas) {
      this.selectedTerritory = [];
      await datas.forEach((data) => {
        this.selectedTerritory.push(data.id);
      });
    },
    onClickSearch() {
      if (this.date == null) {
        this.$vs.notify({
          title: "Warning",
          text: "Please select date",
          color: "warning",
          position: "top-center",
        });
        return;
      }
      let params = {
        status: 1,
        date: this.date,
      };
      if (this.selectedTerritory != null) {
        params.territory_ids = this.selectedTerritory;
      }

      if (this.$refs.table_wait_approval) {
        params.is_waiting_list = true;
        this.$refs.table_wait_approval.getData(params);
      }

      if (this.$refs.table_approved) {
        params.is_approved_list = true;
        this.$refs.table_approved.getData(params);
      }
    },
    onSearchTerritory(query) {
      let company_id = this.$store.state.user.currentUser.company_id;
      this.$http
        .get(
          "api/sfa/v1/territory?company_id=" + company_id + "&search=" + query
        )
        .then((response) => {
          this.optionTerritory = response.data.records;
        });
    },
    onClickPrint(id) {
      this.$vs.loading();
      const currentDate = `${moment().format("YYYY-MM-DD")}`;
      var fileTitle = `COLLECTION_PLAN_REPORT_${currentDate}`;
      moment(this.startDate).format("YYYY-MM-DD") +
        this.$http
          .get(`api/sfa/v1/collection-plan/${id}/excel/export`, {
            responseType: "arraybuffer",
            headers: {
              Accept: "application/octet-stream",
            },
          })
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.status == "error") {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              var fileURL = window.URL.createObjectURL(new Blob([resp]));
              var fileLink = document.createElement("a");
              fileLink.href = fileURL;

              fileLink.setAttribute("download", fileTitle + ".xlsx");
              document.body.appendChild(fileLink);
              fileLink.click();
            }
          });
    },
    async fetchTerritory() {
      let company_id = this.$store.state.user.currentUser.company_id;
      const response = await this.$http.get(
        "api/sfa/v1/territory?company_id=" + company_id
      );

      this.optionTerritory = response.data.records;
    },
    customLabelTerritory({ name, code }) {
      return `${code} - ${name}`;
    },
    async handleShowDetail(id) {
      this.$refs.show.ID = id
      await this.$refs.show.getData()
      this.detailShow = true
		},
		handleClose(){
			this.detailShow = false
			this.$refs.show.ID = 0
		}
  },
};
</script>
